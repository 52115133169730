import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Benutzer Profil',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // import L-Shop
        {
          name: 'Import aus Lshop',
          path: 'pages/import-lshop',
          component: () => import('@/views/dashboard/pages/ImportLshop'),
        },
        // Shopware
        {
          name: 'Shopware Artikel',
          path: 'pages/shopware',
          component: () => import('@/views/dashboard/pages/ShopWare'),
        },
        // Shopware Categories - Ebay Categories
        {
          name: 'Shopware Kategorien',
          path: 'pages/shopware-categories',
          component: () => import('@/views/dashboard/pages/ShopWareCategories'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
        // Documentation
        {
          name: 'VTool Documentation',
          path: 'pages/documentation',
          component: () => import('@/views/dashboard/pages/Documentation'),
        },
      ],
    },
  ],
})
