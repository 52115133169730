import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from 'vuetify/lib/locale/de'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/de.json'),
    $vuetify: de,
  },
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages,
})

// import Vue from 'vue'
// import VueI18n from 'vue-i18n'

// import en from 'vuetify/lib/locale/en'

// Vue.use(VueI18n)

// const messages = {
//   en: {
//     ...require('@/locales/en.json'),
//     $vuetify: en,
//   },
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages,
// })
